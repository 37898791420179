import React from 'react';
import { Link } from 'gatsby';

import imgCard from '../../assets/images/pages/home-page/ori-card-1.svg';

function OrientaionCard({ title, description = '', imgUrl, btn: { to = '#', text = 'Saber más' } }) {
  return (
    <div className="orientaion-card">
      <img src={imgUrl || imgCard} alt="imgCard" />
      <h3 className="orientaion-card__title">{title}</h3>
      {description?.length ? <p className="orientaion-card__description">{description}</p> : null}
      {text.length ? (
        <Link to={to} className="c-btn c-btn--green">
          {text}
        </Link>
      ) : null}
    </div>
  );
}

export default OrientaionCard;
