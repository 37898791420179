import React from 'react';

function OrientationSection({title, description, children }) {
  return (
    <section className="orientaion-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="orientaion-section__title">{title}</h2>
            <p className="orientaion-section__description c-text-15">
              {description}
            </p>
          </div>
        </div>
        {children}
      </div>
    </section>
  );
}

export default OrientationSection;
