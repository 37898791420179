import React from 'react';

import IconQuotes from '../../assets/images/favicon/quotes.inline.svg';

const ReviewCard = ({ data }) => {
  return (
    <div className="review-card">
      <div className="main">
        <div className="main__quotes">
          <IconQuotes />
        </div>
        <div className="c-text-14 main__title">{data?.title?.text}</div>
        <div className="c-text-14 main__content">{data?.quote?.text}</div>
        <div className="review-card__person">
          <img src={data?.user_avatar?.url} alt="photo" className="person__avatar" />
          <p className="person__description">
            <span className="c-text-16 person__name">{data?.user_name?.text}</span>
            <span className="description">{data?.user_description?.text}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
