import React from 'react';
import { Link, graphql } from 'gatsby';

import CFS from '../components/molecules/CFS';
import UniversalCard from '../components/molecules/UniversalCard';
import Layout from '../components/Layout';
import ReviewsSection from '../components/molecules/ReviewsSection';
import OrientationSection from '../components/molecules/OrientationSection';
import OrientaionCard from '../components/atoms/OrientaionCard';
import {
  CONTACTAR_ROUTE,
  DESCARDA_EL_FOLLETO_ROUTE,
  INSCRIPCION_ROUTE,
  ORIENTACION_PROFESIONAL_ROUTE,
  BLOG_ROUTE,
} from '../constants/routes';

import imgAccos from '../assets/images/pages/home-page/asoc.png';
import imgAep from '../assets/images/pages/home-page/aepe.png';
import imgTi from '../assets/images/pages/home-page/ti.png';
import iconT3 from '../assets/images/pages/home-page/t3.svg';
import iconT1 from '../assets/images/pages/home-page/t1.svg';
import iconT2 from '../assets/images/pages/home-page/t2.svg';
import iconT4 from '../assets/images/pages/home-page/t4.svg';
import iconT5 from '../assets/images/pages/home-page/t5.svg';
import iconT6 from '../assets/images/pages/home-page/t6.svg';
import imgStats from '../assets/images/pages/home-page/stats.png';
import imgOriC1 from '../assets/images/pages/home-page/ori-card-1.svg';
import imgOriC2 from '../assets/images/pages/home-page/ori-card-2.svg';
import imgCTA from '../assets/images/pages/home-page/cta.svg';
import imgPartner3 from '../assets/images/pages/acreditaciones-y-asociaciones/partner3.png';
import '../assets/styles/pages/home-page.scss';

const IndexPage = ({ data }) => {
  const featuredPost = data.featuredPost.edges;
  const popularPosts = data.popularPosts.edges;
  const forthcomingCourses = data.prismicHomePage?.data?.forthcoming_courses;

  return (
    <Layout
      title="ELE Barcelona: La Mejor Escuela para ser Profesor de Español"
      description="Cursos todo el año para formarte como Profesor de Español. Prácticos, de calidad, con certificación ELE y servicio de orientación profesional. ¡Infórmate aquí!"
    >
      <div className="home-page">
        <CFS
          isLarge
          title="Formación de profesores de español para extranjeros"
          description="Apúntate en ELE Barcelona y apasiónate de nuevo."
          subsctribeBtn="Inscríbete ahora"
          questionBtn="¿Qué es FELE?"
        />
        <section className="second-section">
          <div className="container">
            <div className="second-section__wrapper">
              <div className="partners">
                <div className="partner">
                  <p>ELE Barcelona es socio oficial de:</p>
                </div>
                <div className="partner">
                  <img src={imgAccos} alt="Asociación para la Enseñanza del Español como Lengua Extranjera" />
                </div>
                <div className="partner">
                  <img src={imgAep} alt="Asociación Europea de Profesores de Español" />
                </div>
                <div className="partner">
                  <img src={imgPartner3} alt="Languages4life" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col c-courses-row">
                <p>Próximos cursos</p>
                {/* <Link to="/">Ver todos los cursos</Link> */}
              </div>
            </div>
            <div className="row">
              {forthcomingCourses?.map((course, index) => (
                <div className="col-md-4 col-sm-6 col-12" key={index}>
                  <UniversalCard
                    withShadow
                    notice={course?.label}
                    title={course?.title?.text || course?.title?.[0]?.text}
                    description={`Próxima formación: ${course?.date}`}
                    btn={{ to: course?.link, text: 'Saber más' }}
                    img={course?.image?.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="third-section__inner">
            <div className="wrapper container">
              <div className="block_l">
                <h3 className="c-title-34">ELE Barcelona te ayudará a crecer. Aquí verás cómo:</h3>
              </div>
              <div className="block_r">
                <div className="benefit">
                  <img src={iconT1} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Título reconocido internacionalmente</h4>
                    <p className="c-text-14">
                      ¿Barcelona? ¿Bristol? ¿Bangkok? El certificado FELE está reconocido por el
                      mundo.
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={iconT2} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Mucha práctica real</h4>
                    <p className="c-text-14">
                      Más de 10 horas de clases prácticas con estudiantes reales.
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={iconT3} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Orientación profesional</h4>
                    <p className="c-text-14">
                      Nos empeñamos en asegurar que todos nuestros graduados encuentren trabajo
                      cuanto antes
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={iconT4} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Instalaciones vanguardistas</h4>
                    <p className="c-text-14">
                      Equipos Mac, pizarras digitales y wifi rápido en cada aula.
                    </p>
                  </div>
                </div>

                <div className="benefit">
                  <img src={iconT5} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Grupos reducidos</h4>
                    <p className="c-text-14">Un máximo de 8 alumnos en cada grupo.</p>
                  </div>
                </div>
                <div className="benefit">
                  <img src={iconT6} alt="" />
                  <div className="benefit__text">
                    <h4 className="c-title-18">Observación de profesores</h4>
                    <p className="c-text-14">
                      Aprende a dar clases observando a profesores y aprende a dar feedback
                      profesional
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fourth-section">
          <ReviewsSection title={'Nuestros graduados'} />
        </div>

        <section className="fifth-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="c-title-34">
                  Hemos ayudado a muchos estudiantes a aprender a dar clases de español. ¿Serás tú
                  el próximo?
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="img-container">
                  <img src={imgStats} alt="" />
                  <div className="overlay-text">
                    <p>
                      ELE puede tener el significado que tú le quieras dar. Un pasaporte para poder
                      viajar a países extranjeros y trabajar en algo que te gusta al mismo tiempo,
                      una nueva oportunidad, una nueva profesión, o una nueva vida.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="item">
                  <h3 className="item__title">300+</h3>
                  <p className="item__description">Historias de éxito ELE desde 2015</p>
                </div>
                <div className="item">
                  <h3 className="item__title">60+</h3>
                  <p className="item__description">
                    Profesores graduados no nativos que se han formado como profesores de español
                  </p>
                </div>
                <div className="item">
                  <h3 className="item__title">90%</h3>
                  <p className="item__description">
                    De nuestros nuevos profesores se dedican a dar clases de español tras hacer el
                    curso
                  </p>
                </div>
              </div>
            </div>
            <div className="cta-block row">
              <div className="col">
                <div className="cta">
                  <div className="left">
                    <h2 className="cta__title">¿Tienes preguntas? Pregúntanos lo que quieras</h2>
                  </div>
                  <div className="right">
                    <Link to={CONTACTAR_ROUTE} className="c-btn c-btn--red">
                      Contáctanos
                    </Link>
                    <Link to={DESCARDA_EL_FOLLETO_ROUTE} className="c-btn c-btn--white">
                      Descarga el folleto
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <OrientationSection
          title="¡Aquí estás en buenas manos!"
          description="ELE Barcelona es más que una escuela de formación. Nuestro increíble equipo te dará todo el apoyo en lo que necesites: orientación profesional, planificación de clases, creación de materiales, alojamiento, burocracia y mucho más."
        >
          <div className="row">
            <div className="col-lg-6">
              <OrientaionCard
                btn={{ to: ORIENTACION_PROFESIONAL_ROUTE, text: 'Saber más' }}
                imgUrl={imgOriC1}
                title="Apoyo al nuevo profesor"
                description="Queremos que tu formación no tenga límites una vez que termines el curso. Por eso, estamos a tu disposición para asesorarte en todo lo relacionado con el día a día del profesor: planes de clase, creación de materiales, contenido académico y cualquier necesidad que tengas."
              />
            </div>
            <div className="col-lg-6">
              <OrientaionCard
                btn={{ to: ORIENTACION_PROFESIONAL_ROUTE, text: 'Saber más' }}
                imgUrl={imgOriC2}
                title="Orientación profesional"
                description="Encuentra el trabajo de tus sueños con ELE Barcelona. Nuestros tutores tienen mucha experiencia en el mercado laboral y una extensa red de contactos así que estarán en contacto permanente contigo para enviarte ofertas de trabajo y cursos de formación continua."
              />
            </div>
          </div>
        </OrientationSection>

        <section className="seventh-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="seventh-section__title c-title-34">
                  Echa un vistazo a nuestro blog
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  {featuredPost.map(({ node }, index) => (
                    <div className="col-lg-6" key={index}>
                      <UniversalCard
                        title={node?.data?.title?.text}
                        description={`${node?.data?.content?.text.slice(0, 250)}...`}
                        btn={{ to: `/blog/${node.uid}`, text: 'Leer más' }}
                        img={node?.data?.image?.url}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-4">
                {popularPosts.map(({ node }, index) => (
                  <Link to={`/blog/${node.uid}`} className="blog-aside" key={index}>
                    <h3 className="blog-aside__title">{node?.data?.title?.text}</h3>
                    <p className="blog-aside__description">
                      {`${node?.data?.content?.text.slice(0, 150)}...`}
                    </p>
                  </Link>
                ))}

                <Link to={BLOG_ROUTE} className="c-btn c-btn--green btn-details">
                  Ver todas las entradas
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="eigth-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="left">
                  <h3 className="left__title">
                    ¿Preparado/a para dar tus primeros pasos en tu nueva profesión?
                  </h3>
                  <p className="left__description">
                    Hoy en día es más fácil que nunca convertirte en profesor de español. Inscríbete
                    ahora para encontrar el trabajo de tus sueños y hacer lo que realmente te gusta.
                  </p>
                  <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                    Inscríbete ahora
                  </Link>
                </div>
              </div>
              <div className="col-lg-7">
                <img className="cta-img" src={imgCTA} alt="imgCTA" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicHomePage {
      data {
        forthcoming_courses {
          title {
            text
          }
          label
          date(formatString: "D MMMM", locale: "es")
          image {
            url
          }
          link
        }
      }
    }
    featuredPost: allPrismicArticle(
      filter: { data: { featured_post: { eq: true } } }
      limit: 2
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    popularPosts: allPrismicArticle(
      filter: { data: { popular_post: { eq: true } } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
