import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import { Link, graphql, useStaticQuery } from 'gatsby';

import ReviewCard from '../atoms/ReviewsCard';

import imgMockAva from '../../assets/images/favicon/Ellipse 3.png';

const ReviewsSection = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicTestimonials(sort: { order: DESC, fields: last_publication_date }) {
        edges {
          node {
            id
            data {
              title {
                text
              }
              quote {
                text
              }
              user_avatar {
                url
              }
              user_name {
                text
              }
              user_description {
                text
              }
            }
          }
        }
      }
    }
  `);
  const allPrismicTestimonials = data.allPrismicTestimonials.edges;
  // let newallPrismicTestimonials = allPrismicTestimonials.slice(0, 2);

  const slidersSettings = {
    settings: {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      centerMode: true,
      speed: 500,
      arrow: false,
      centerPadding: '50px',
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            centerPadding: '25px',
            slidesToShow: 1,
          },
        },
      ],
    },
    settings2: {
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      speed: 500,
      arrow: false,
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div className="c-reviews container">
        <div className="row">
          <div className="col">
            <h2 className="c-title-34">{title}</h2>
          </div>
        </div>
        <Slider
          className={`${allPrismicTestimonials.length < 3 ? 'slick-special' : ''}`}
          {...slidersSettings[allPrismicTestimonials.length < 3 ? 'settings2' : 'settings']}
        >
          {allPrismicTestimonials.map(({ node }) => {
            return (
              <div key={node.id}>
                <ReviewCard data={node.data} />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default ReviewsSection;
